import React from 'react';

import Layout from '../components/layout';

function NotFoundPage() {
  return (
    <Layout>
      <div className="mx-auto lg:w-5/6 text-center">
          <h1>Pagina niet gevonden!</h1>
      </div>
    </Layout>
  );
}

export default NotFoundPage;
